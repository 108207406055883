<template>
  <article class="announcement-detail">
    <!-- <HomeBtn /> -->
    <PageTitle
      title="重要公告"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />

    <main v-loading="loading" class="content-wrapper">
      <p>{{ announcement.title }}</p>
      <p class="time">{{ formatDate(announcement.publishedAt) }}</p>
      <div class="markdown-body">
        <div v-html="announcement.content" />
      </div>
    </main>
  </article>
</template>

<script>
// import HomeBtn from './HomeBtn.vue'
import { defineComponent, onMounted } from 'vue'
import { FindAnnouncement } from '@/api/homePage'
import { cleanHtml } from '@/utils/helper'
import { formatDate } from '@/utils/date'
import { useNewsHint } from '@/use/useNewsHint'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'AnnouncementDetail',
  // components: { HomeBtn },
  setup () {
    const { articleRead } = useNewsHint()
    const router = useRoute()
    onMounted(() => {
      articleRead(router.params.id)
    })
  },
  data: () => ({
    loading: false,
    announcement: {},
  }),
  computed: {
    announcementId () {
      return this.$route.params.id
    },
  },
  async mounted () {
    console.log('in')
    this.loading = true
    await this.findAnnouncement()
    this.loading = false
  },
  methods: {
    cleanHtml,
    formatDate,
    async findAnnouncement () {
      const [res, err] = await FindAnnouncement({
        id: this.announcementId,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.announcement = res
    },
  },
})
</script>

<style lang="scss" scoped>
.announcement-detail {
  @apply relative;
}
.content-wrapper {
  @apply bg-white rounded-[10px] p-[20px];
  @apply flex flex-col;
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
}

.time {
  @apply text-gray-60 text-sm mt-[20px] mb-[26px];
}
</style>
